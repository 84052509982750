<script setup lang="ts">
import { ContentResults, Content } from "@/types/Facet";
import { ORDER_PARAM } from '@/composables/useFacetHandler'

const { isDesktop } = useDevice();
const { $fireBangApiDomain } = useNuxtApp();
// 記事一覧取得
// 火災保険の基礎知識
const { data: fetchBasic } = await useFetch(
  `${$fireBangApiDomain}/api/facet/cate/basic`,
  {key:'index1',
    query: {
      limit: 3,
      order: ORDER_PARAM.SORT_NUMBER
    },
    transform: (data: ContentResults) => {
      const results = data ? data.results : [];
      return results;
    },
  }
);
// basic変数の型をContent[]型に明示的に指定する
const basic: Content[] = Array.isArray(fetchBasic.value) ? fetchBasic.value : [];

// 火災保険の基礎知識 4記事目以降
const { data: fetchBasicUpdate } = await useFetch(
  `${$fireBangApiDomain}/api/facet/cate/basic`,
  {key:'index2',
    query: {
      offset: 3,
      limit: 3,
      order: ORDER_PARAM.SORT_NUMBER
    },
    transform: (data: ContentResults) => {
      const results = data ? data.results : [];
      return results;
    },
  }
);
const basicUpdate: Content[] = Array.isArray(fetchBasicUpdate.value) ? fetchBasicUpdate.value : [];

// 	地震保険の基礎知識
const { data: fetchJishinBasic } = await useFetch(
  `${$fireBangApiDomain}/api/facet/cate/jishin-basic`,
  {key:'index3',
    query: {
      limit: 3,
      order: ORDER_PARAM.SORT_NUMBER
    },
    transform: (data: ContentResults) => {
      const results = data ? data.results : [];
      return results;
    },
  }
);

const jishinBasic: Content[] = Array.isArray(fetchJishinBasic.value) ? fetchJishinBasic.value : [];

const isFloating = ref(false);
const isClose = ref(false);

const closeFloating = () => {
  const floating = document.getElementsByClassName('floating')
  isFloating.value = false;
  isClose.value = true;
  floating[0].remove()
};

const handleScroll = () => {
  const body = document.getElementsByTagName('body');
  const bodyHeight = body[0].clientHeight;
  const counter = document.getElementsByClassName('counter');
  const counterHeight = counter[0].clientHeight;
  const ranking = document.getElementsByClassName('ranking');
  const rankingHeight = ranking[0].clientHeight;
  const terms = document.getElementsByClassName('terms');
  const termsHeight = terms[0].clientHeight;
  const footer = document.getElementsByTagName('footer');
  const footerHeight = footer[0].clientHeight;
  const pullHeight = rankingHeight + counterHeight + termsHeight + footerHeight;
  const displayPosition = bodyHeight - pullHeight;

  if (window.scrollY > displayPosition) {
    isFloating.value = true;
  } else {
    isFloating.value = false;
  }
};

onMounted(() => {
  handleScroll
  closeFloating
});

useHead({
  bodyAttrs: {
    class: ["top"],
  },
});

useSeoMeta({
  titleTemplate: "",
  title: "火災保険の一括見積もり・比較【無料】｜保険スクエアbang! 火災保険",
  keywords: "火災保険,保険会社,比較,ランキング,資料請求,申込み,地震保険,家財保険",
  description:
    "火災保険を比較するなら【保険スクエアbang! 火災保険】にお任せ！最大15社41商品の火災保険を無料で比較・見積もりし、あなたにぴったりで無駄のない補償を保険アドバイザーがご提案いたします。火災保険の選び方や基礎知識など、初心者にもわかりやすく解説。",
  ogTitle: "火災保険の一括見積もり・比較【無料】｜保険スクエアbang! 火災保険",
  ogDescription:
    "火災保険を比較するなら【保険スクエアbang! 火災保険】にお任せ！最大15社41商品の火災保険を無料で比較・見積もりし、あなたにぴったりで無駄のない補償を保険アドバイザーがご提案いたします。火災保険の選び方や基礎知識など、初心者にもわかりやすく解説。",
  ogUrl: "https://fire.bang.co.jp/",
});
</script>

<template>
  <div class="container">
    <section class="mainVisual">
      <div class="mainVisual-header">
        <h1>
          <img
            src="~/assets/images/top/main_text.png"
            width="640"
            height="160"
            alt="火災保険の無料見積もり・比較なら火災保険比較"
          />
        </h1>
        <img
          src="~/assets/images/top/max_coms.png"
          width="230"
          height="160"
          class="mainVisual-maxComs deviceisDesktop"
        />
        <img
          src="~/assets/images/top/max_coms_sp.png"
          width="640"
          height="299"
          class="mainVisual-maxComs deviceisMobile"
          alt="最大41商品を比較!"
        />
        <!-- .mainVisual-header -->
      </div>
      <div class="deviceisDesktop">
        <div class="mainVisual-content">
          <div class="comList-wrap">
            <div class="comList-title">
              <div><span>最大<strong>41</strong>商品</span>を比較!</div>
            </div>
            <OrganismsComsList/>
          </div>
          <div class="mainVisual-request">
            <img
              src="~/assets/images/top/button_catch.png"
              width="610"
              height="47"
              alt="カンタン3分！最短即日見積もり"
              class="mainVisual-request-catch"
            />
            <a href="/step/step_init.html" class="button-step1" id="button-step1_mainVisual"
              ><span>無料</span>
              <div>見積もり依頼スタート!</div></a
            >
            <a
              id="button-kyousai"
              href="https://www.zenrosai.coop/kyousai/kasai/lp/campaign-index-3.html?utm_source=Unknown&utm_medium=not_payid&utm_term=&utm_content=&utm_campaign=Kantou&argument=Q6fa96MK&dmai=a656007e4db9da"
              target="_blank"
              class="button-kyousai"
            >
              火災共済をご希望の方はこちら<br /><span
                >（こくみん共済 coop サイト）</span
              >
            </a>
            <div class="request-other">
              <a
                href="https://www.zurichssi.co.jp/kazai-lp/?utm_medium=sdzp&utm_source=siwcad&utm_campaign=bn&utm_content=common&ssi_agencyCode=000"
                class="button-lease"
                target="_blank"
                rel="noopener"
                id="button-lease_mainVisual"
                ><div>賃貸物件の方はこちら</div>
                <span>（外部サイト）</span></a
              >
              <a href="/hojin/step/step_init.html" class="button-hojin" id="button-hojin_mainVisual">
                <div>法人向け火災保険はこちら</div></a
              >
            </div>
            <!-- .mainVisual-request -->
          </div>
        </div>
      </div>
      <div class="deviceisMobile">
        <div class="mainVisual-request">
          <img
            src="~/assets/images/top/button_catch.png"
            width="610"
            height="47"
            alt="カンタン3分！最短即日見積もり"
            class="mainVisual-request-catch"
          />
          <a href="/step/step_init.html" class="button-step1" id="button-step1_mainVisual"
            ><span>無料</span>
            <div>見積もり依頼スタート！</div></a
          >
          <a
            id="button-kyousai"
            href="https://www.zenrosai.coop/kyousai/kasai/lp/campaign-index-3.html?utm_source=Unknown&utm_medium=not_payid&utm_term=&utm_content=&utm_campaign=Kantou&argument=Q6fa96MK&dmai=a656007e4db9da"
            target="_blank"
            class="button-kyousai"
          >
            火災共済をご希望の方はこちら<br /><span
              >（こくみん共済 coop サイト）</span
            >
          </a>
          <div class="request-other">
            <a
              href="https://www.zurichssi.co.jp/kazai-lp/?utm_medium=sdzp&utm_source=siwcad&utm_campaign=bn&utm_content=common&ssi_agencyCode=000"
              class="button-lease"
              target="_blank"
              rel="noopener"
              id="button-lease_mainVisual"
              ><div>賃貸物件の方はこちら</div>
              <span>（外部サイト）</span></a
            >
            <a href="/hojin/step/step_init.html" class="button-hojin" id="button-hojin_mainVisual">
              <div>法人向け火災保険はこちら</div></a
            >
          </div>
          <!-- .mainVisual-request -->
        </div>
        <OrganismsInformation  />
        <OrganismsComsList/>
      </div>
      <!-- .mainVisual -->
    </section>
    <section class="anchor">
      <div>
        <h2>
          こんな方におすすめ
        </h2>
        <ul>
          <li>
            <a href="#anchorNew" v-smooth-scroll
              >新しく火災保険を契約したい方</a
            >
          </li>
          <li>
            <a href="#anchorUpdate" v-smooth-scroll
              >火災保険の更新・見直しをしたい方</a
            >
          </li>
          <li>
            <a href="#anchorEarthquake" v-smooth-scroll
              >地震保険について知りたい方</a
            >
          </li>
          <li>
            <a href="#anchorBeginner" v-smooth-scroll
              >見積もり依頼サービスの使い方</a
            >
          </li>
        </ul>
      </div>
    </section>
    <div class="deviceisDesktop">
      <OrganismsInformation  />
    </div>
    <div class="wrap-contents">
      <div class="main-contents">
        <section id="anchorNew" class="cate">
          <h2 class="new">
            <span>新しく火災保険を契約したい方</span>
          </h2>
          <p>
            新築物件・中古物件問わず家を購入するなら、火災保険への加入で万が一のリスクに備えることができます。火災保険の必要性や選び方など、契約前に押さえておきたいポイントは、以下の記事が参考になります。
          </p>
          <div class="cate-recommend">
            <div class="cate-recommend-title">
              <img
                src="~/assets/images/icon_point.png"
                width="128"
                height="39"
                alt="POINT"
              />こんな人におすすめ！
            </div>
            <ul>
              <li>必要な補償がわからない</li>
              <li>何を基準に選んでよいのかわからない</li>
              <li>住宅を購入し、火災保険を検討している</li>
            </ul>
          </div>
        <OrganismsLargeContList :fetchFacetContList="basic" />
          <!-- .cate -->
        </section>
        <a href="/step/step_init.html" class="button-step1" id="button-step1_kasai1"
          ><span>無料</span>
          <div v-if="isDesktop">見積もり依頼スタート！</div>
          <div v-else class="button-catch">見積もりを依頼する</div>
        </a>

        <section id="anchorUpdate" class="cate">
          <h2 class="new">
            <span>火災保険の更新・見直しをしたい方</span>
          </h2>
          <p>
            すでに火災保険に加入しており、契約更新を迎える方はこの機会に見直しをしませんか。長らく契約内容を見直していない場合、建物評価額の算出方法が適切でなかったり、家財の補償に過不足があったり、といった可能性があります。
          </p>
          <div class="cate-recommend">
            <div class="cate-recommend-title">
              <img
                src="~/assets/images/icon_point.png"
                width="128"
                height="39"
                alt="POINT"
              />こんな人におすすめ！
            </div>
            <ul>
              <li>今の保険料が最適かどうかわからない</li>
              <li>かなり昔に契約して以来、一度も見直していない</li>
              <li>同居する家族の人数が変わって、家財の数が変わった</li>
            </ul>
          </div>
        <OrganismsLargeContList :fetchFacetContList="basicUpdate" />
          <!-- .cate -->
        </section>
        <a href="/step/step_init.html" class="button-step1" id="button-step1_kasai2"
          ><span>無料</span>
          <div v-if="isDesktop">見積もり依頼スタート！</div>
          <div v-else class="button-catch">見積もりを依頼する</div>
        </a>

        <section id="anchorEarthquake" class="cate">
          <h2 class="earthquake">
            <span>地震保険について知りたい方</span>
          </h2>
          <p>
            近年、地震による被害が増大しており、ますます需要が高まっている地震保険。地震保険は、火災保険とセットで契約する必要があり、建物の耐震構造などが保険料に大きく影響します。さらに詳しく知りたい方は、以下の記事をぜひ参考にしてください。
          </p>
          <div class="cate-recommend">
            <div class="cate-recommend-title">
              <img
                src="~/assets/images/icon_point.png"
                width="128"
                height="39"
                alt="POINT"
              />こんな人におすすめ！
            </div>
            <ul>
              <li>地震保険の契約をすると保険料がどう変わるのか知りたい</li>
              <li>地震保険に入るべき理由を知りたい</li>
              <li>地震保険の補償内容について知りたい</li>
            </ul>
          </div>
        <OrganismsLargeContList :fetchFacetContList="jishinBasic" />
          <!-- .cate -->
        </section>
        <a href="/step/step_init.html" class="button-step1" id="button-step1_jishin1"
          ><span>無料</span>
          <div v-if="isDesktop">見積もり依頼スタート！</div>
          <div v-else class="button-catch">見積もりを依頼する</div>
        </a>

        <section id="anchorBeginner" class="beginner">
          <h2>見積もり依頼サービスご利用の流れ</h2>
          <p class="beginner-lead">
            どなたにも安心してご利用いただけるよう、最適な保険選びのサポートをいたします。何度ご相談いただいても無料です！
          </p>
          <div class="beginner-flow">
            <ol>
              <li class="beginner-flow-step01">
                <h4>
                  <img
                    src="~/assets/images/step01.png"
                    width="164"
                    height="40"
                    alt="STEP.01"
                  />見積もり申し込み
                </h4>
                <p>
                  このページの<strong>「今すぐ無料見積もり！」ボタンをクリック</strong>して、見積もりに必要な情報をご入力ください。
                </p>
              </li>
              <li class="beginner-flow-step02">
                <h4>
                  <img
                    src="~/assets/images/step02.png"
                    width="164"
                    height="40"
                    alt="STEP.02"
                  />見積もり・比較
                </h4>
                <p>
                  このページのご入力いただいた情報をもとに、<strong
                    >保険アドバイザーが見積もりを作成します。</strong
                  >
                </p>
              </li>
              <li class="beginner-flow-step03">
                <h4>
                  <img
                    src="~/assets/images/step03.png"
                    width="164"
                    height="40"
                    alt="STEP.03"
                  />加入のご検討
                </h4>
                <p>
                  このページのお見積りをもとにご検討ください。<strong>保険アドバイザーへの無料相談も行っております。</strong>※無理な勧誘等は一切ございません！
                </p>
              </li>
            </ol>
          </div>
          <!-- .beginner -->
        </section>

        <section class="voice">
          <h2>ご利用された方の声</h2>
          <ul class="voice-list">
            <li>
              <div class="voice-list-man">
                <img
                  src="~/assets/images/label_mansion.png"
                  width="164"
                  height="38"
                  alt="マンション"
                />東京都<br />Tさん 30代
              </div>
              <div class="voice-list-detail">
                <span>納得感のある保険選びができました。</span>友人から、「bang!
                火災保険」は対応が早かったと聞き、利用しました。火災保険のことはよくわからないので、無料相談サービスを利用しました。<br />保険料がどのように決まるのか、自分にはどんな保険が適しているか、プロの方に教えていただき、すごく納得感がありました。<br />生活が変わるタイミングで、火災保険を見直すほうがよいということも聞いたので、仕事を変えたときや家族に変化があった時は、また相談しようと思います。
              </div>
            </li>
            <li>
              <div class="voice-list-woman">
                <img
                  src="~/assets/images/label_house.png"
                  width="164"
                  height="38"
                  alt="戸建て"
                />石川県<br />Hさん 40代
              </div>
              <div class="voice-list-detail">
                <span>他の比較サービスよりも、見積もりが正確でした。</span
                >火災保険の見直しを検討しているときに、「bang!
                火災保険」を見つけたので、利用しました。結果、他の比較サービスを使ったときよりもさらに保険金額が低い補償を提示してもらえました。<br />私は保険アドバイザーの方に相談したのですが、「家財保険の補償額が高すぎる」「水災の補償は不要」など、自分で調べてもよくわからなかったことまで、丁寧にアドバイスをいただきました。相談は無料だし、自分で調べるよりも早くて正確な見積もりがもらえたので、相談してよかったと思います。
              </div>
            </li>
          </ul>
          <!-- .voice -->
        </section>
        <a href="/step/step_init.html" class="button-step1" id="button-step1_voice"
          ><span>無料</span>
          <div v-if="isDesktop">見積もり依頼スタート！</div>
          <div v-else class="button-catch">見積もりを依頼する</div>
        </a>

        <section id="anchorRanking" class="ranking">
          <h2>火災保険人気ランキング</h2>
          <p class="ranking-lead">
            人気の火災保険が一目でわかる契約件数の総合ランキングです。火災保険選びの参考にお役立てください。
          </p>
          <div class="ranking-info">
            ＜情報提供について＞<br />
            以下のランキングは、株式会社損害保険見直し本舗を経由して申し込んた契約件数のランキングです。株式会社損害保険見直し本舗は、以下の保険会社と代理店委託契約を締結し、損害保険の募集行為及び媒介を行っています。
          </div>
          <div class="ranking-list">
            <ul>
              <li class="rank01">
                <img
                  src="~/assets/images/rank_01.png"
                  width="131"
                  height="163"
                  alt="1位"
                  class="rank"
                />
                <div class="detail">
                  <img
                    src="~/assets/images/company_logo/logo_tokyokaijou.gif"
                    width="230"
                    height="112"
                    alt="東京海上日動 "
                    class="logo"
                  />
                  <div class="name">
                    <span>東京海上日動</span>トータルアシスト住まいの保険
                  </div>
                </div>
              </li>
              <li class="rank02">
                <img
                  src="~/assets/images/rank_02.png"
                  width="131"
                  height="163"
                  alt="2位"
                  class="rank"
                />
                <div class="detail">
                  <img
                    src="~/assets/images/company_logo/logo_secom.gif"
                    width="230"
                    height="112"
                    alt="セコム損保"
                    class="logo"
                  />
                  <div class="name">
                    <span>セコム損保</span>セコム安心マイホーム保険
                  </div>
                </div>
              </li>
              <li class="rank03">
                <img
                  src="~/assets/images/rank_03.png"
                  width="131"
                  height="163"
                  alt="3位"
                  class="rank"
                />
                <div class="detail">
                  <img
                    src="~/assets/images/company_logo/logo_ioi.gif"
                    width="230"
                    height="112"
                    alt="あいおいニッセイ同和損保"
                    class="logo"
                  />
                  <div class="name">
                    <span>あいおいニッセイ同和損保</span
                    >タフ・住まいの保険（家庭総合保険）
                  </div>
                </div>
              </li>
            </ul>
            <div class="ranking-link">
              <a href="/ranking/"
                ><span><b>建物別ランキング</b>はこちら</span></a
              >
            </div>
            <span class="ranking-note"
              >集計期間：2021年4月1日～2021年4月30日</span
            >
          </div>
          <!-- .ranking -->
        </section>
        <!-- .main-contents -->
      </div>
      <OrganismsSideContents :sideCateDisplay="true" />
      <!-- .wrap-contents -->
    </div>
    <transition name="fade">
      <div v-show="isFloating" v-scroll="handleScroll" class="floating">
        <div>
          <div class="floating-button">
            <div class="floating-button-catch">
              <span>最大<span>41</span>商品</span>から無料見積もり!
            </div>
            <a href="/step/step_init.html" class="button-step1" id="button-step1_floating"
              ><span>無料</span>
              <div>見積もり依頼スタート！</div>
            </a>
          </div>
          <a class="floating-close sp" @click="closeFloating()"
            ><img
              src="~/assets/images/icon_close_sp.png"
              width="48"
              height="48"
              alt=""
          /></a>
          <a class="floating-close pc" @click="closeFloating()"
            ><img
              src="~/assets/images/icon_close_pc.png"
              width="48"
              height="48"
              alt=""
          /></a>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
/*---------------------------------------
.button
----------------------------------------*/
.button-step1 {
  margin: 0 auto 14%;
  padding: 4.4% 0;
  border-radius: 6px;
  display: block;
  width: 92%;
  color: #fff;
  font-weight: bold;
  background-color: $colorBasicButton;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.16);
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  transition: 0.1s all;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.3);
  > span {
    margin: 0 0.1em 0 0;
    padding: 0.2em 0.2em 0.3em;
    display: inline-block;
    color: #f00;
    font-size: px(17);
    font-weight: 700;
    background-color: #ff0;
    line-height: 1;
    vertical-align: middle;
  }
  > div {
    padding: 0 8% 0 0;
    display: inline-block;
    color: #fff;
    font-size: px(21);
    font-weight: 700;
    background: url(~/assets/images/arrow_orange_sp.png) no-repeat right center;
    background-size: 10% auto;
    vertical-align: middle;
    text-shadow: 2px 2px 0px rgba(241, 90, 36, 0.4);
  }
}
.mainVisual-request {
  & .button-step1 {
    margin-bottom: 2.7%;
    width: 100%;
    > div {
      background: url(~/assets/images/arrow_orange_sp.png) no-repeat right 1%
        center;
      background-size: 9% auto;
    }
  }
}
.side-request-button {
  & .button-step1 {
    margin-bottom: 0;
  }
}
.button-lease {
  margin: 0 auto 3%;
  padding: 3% 1%;
  border-radius: 4px;
  width: 93%;
  display: block;
  background: $colorSubButton url(~/assets/images/arrow_blue_sp.png) no-repeat
    94% center;
  background-size: 7% auto;
  text-align: center;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.16);
  text-decoration: none;
  box-sizing: border-box;
  span {
    margin: 0 0 0 -2%;
    display: inline-block;
    color: #333;
    font-size: px(9.9);
    font-weight: 400;
  }
  > div {
    margin: 0 auto;
    display: inline-block;
    color: $colorBlue;
    font-weight: 700;
  }
}
.button-hojin {
  margin: 0 auto;
  padding: 3% 1%;
  border-radius: 4px;
  width: 93%;
  display: block;
  background: $colorSubButton url(~/assets/images/arrow_blue_sp.png) no-repeat
    94% center;
  background-size: 7% auto;
  text-align: center;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.16);
  text-decoration: none;
  box-sizing: border-box;
  > div {
    margin: 0 auto;
    display: inline-block;
    color: $colorBlue;
    font-weight: 700;
  }
}
.button-linkTopics {
  background-color: #fff;
  a {
    display: inline-block;
    color: $colorBlue;
    font-size: px(11);
    &:hover {
      text-decoration: underline;
    }
  }
}
.button-linkTopics a {
  display: inline-block;
  color: #1e86c6;
  font-size: 74%;
  &:hover {
    text-decoration: underline;
  }
}

.button-kyousai {
  margin: 0 0 4.8%;
  display: inline-block;
  text-align: center;
  color: #148cde;
  font-size: 94%;
  text-decoration: underline;
  span {
    font-size: 79%;
  }
}

/*---------------------------------------
.request
----------------------------------------*/
.request {
  margin: 6% 4% 10%;
  border: 4px solid $colorBlue;
  background-color: $colorBasicBackground;
}
.request-catch {
  margin: 0.3em auto 0.5em;
  width: 15.6em;
  display: block;
  color: #fff;
  font-size: px(18);
  font-weight: 700;
  position: relative;
  text-align: center;
  text-shadow: 2px 2px 0 rgba(30, 134, 198, 0.4);
  &::before,
  &::after {
    content: '';
    width: 1px;
    height: 80%;
    display: block;
    background-color: #fff;
    position: absolute;
    top: 15%;
  }
  &::before {
    left: -0.4em;
    transform: rotate(-45deg);
  }
  &::after {
    right: -0.4em;
    transform: rotate(45deg);
  }
  b {
    color: #fff800;
    font-weight: 700;
  }
}
.request-button {
  padding: 5% 4%;
  background-color: #fff;
  text-align: center;
}
.request {
  .button-hojin {
    padding: 3% 8%;
    text-align: left;
    > div {
      font-size: px(12);
    }
  }
}

/*---------------------------------------
.mainVisual
----------------------------------------*/
.mainVisual {
  padding: 6% 0;
  border-top: 1px solid #dcdcdc;
  box-sizing: border-box;
  background: rgb(247, 246, 241); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(247, 246, 241, 1) 0%,
    rgba(255, 255, 255, 1) 44%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(247, 246, 241, 1) 0%,
    rgba(255, 255, 255, 1) 44%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(247, 246, 241, 1) 0%,
    rgba(255, 255, 255, 1) 44%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.mainVisual-header {
  margin: 0 auto;
  padding: 0 6% 2.1%;
  box-sizing: border-box;
  h1 {
    margin-bottom: 2.13%;
  }
}
.mainVisual-request {
  margin: 0 auto 6.4%;
  padding: 0 5.8%;
  box-sizing: border-box;
  text-align: center;
}
.mainVisual-request-catch {
  margin: 0 auto 0.5em;
  width: 90%;
}

/*---------------------------------------
.anchor
----------------------------------------*/
.anchor {
  display: block;
  margin: 0 4.3%;
  background: #f2f2f2;
  > div {
    padding: 3.7% 4.3% 5%;
    h2 {
      margin-bottom: 2.7%;
      font-weight: bold;
      border-bottom: 1px solid #cbcbcb;
      b {
        color: #ff0;
      }
    }
    li {
      &:not(:last-of-type) {
        margin: 0 0 2.1%;
      }
      a {
        color: $colorDarkBlue;
        text-decoration: underline;
        > div {
          padding: 0 0 0 18%;
          font-weight: 700;
        }
        span {
          display: block;
          color: #2256a1;
          font-size: px(19.4);
          font-weight: 700;
        }
      }
      &.anchor-comparison {
        a {
          > div {
            background: url(~/assets/images/icon_comparison.png) no-repeat left
              center;
            background-size: 12% auto;
          }
        }
      }
      &.anchor-ranking {
        a {
          > div {
            background: url(~/assets/images/icon_ranking.png) no-repeat left
              center;
            background-size: 12% auto;
          }
        }
      }
      &.anchor-beginner {
        a {
          > div {
            background: url(~/assets/images/icon_beginner.png) no-repeat 3%
              center;
            background-size: 7% auto;
          }
        }
      }
      &.anchor-proposal {
        a {
          > div {
            background: url(~/assets/images/icon_proposal.png) no-repeat 2%
              center;
            background-size: 11% auto;
          }
        }
      }
    }
  }
}

/*---------------------------------------
.wrap-contents
----------------------------------------*/
.wrap-contents {
  padding: 0 0 25%;
  position: relative;
}

.pageTop {
  @include pageTop(sp);
}
/*---------------------------------------
.main-contents
----------------------------------------*/
.main-contents {
  padding: 13% 0 0;
  section {
    h2 {
      margin: 0 0 1em;
      padding: 2.4% 4% 2.4% 13%;
      border-top: 2px solid $colorContentsBorder;
      border-bottom: 1px solid #fff;
      font-size: px(18);
      font-weight: 700;
      box-shadow: 0px 1px 0px 0px #dcdcdc;
    }
    p[class$='-lead'] {
      padding: 0 4% 1.5em;
      font-size: px(12.9);
      line-height: 1.77;
    }
  }
}

/*---------------------------------------
.cate
----------------------------------------*/
.cate {
  padding: 0 0 6.4%;
  h2 {
    margin: 0 0 1em;
    padding: 2.4% 4% 2.4% 13%;
    border-top: 2px solid $colorContentsBorder;
    border-bottom: 1px solid #fff;
    font-size: px(18);
    font-weight: 700;
    box-shadow: 0px 1px 0px 0px #dcdcdc;
    background:
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(244, 244, 244, 1) 100%
    );
    &.new {
      background: url(~/assets/images/icon_fire.png) no-repeat 5% center;
      background-size: 6% auto, 100% 100%;
    }
    &.earthquake {
      background: url(~/assets/images/icon_earthquake.png) no-repeat 5% center;
      background-size: 6% auto, 100% 100%;
    }
  }
  p {
    padding: 0 4% 1.5em;
    font-size: px(12.9);
    line-height: 1.77;
  }
  .cate-recommend {
    margin: 0 4% 5%;
    padding: 4%;
    background: url(~/assets/images/bg_pattern_02.gif) no-repeat 0 0;
    background-size: 100% 100%;
    .cate-recommend-title {
      margin: 0 0 0.5em;
      color: $colorDarkBlue;
      font-weight: 700;
      img {
        margin: 0 0.3em 0 0;
        width: 20%;
        display: inline-block;
        vertical-align: bottom;
      }
    }
    li {
      padding: 0 0 0 1.7em;
      font-size: px(12.9);
      font-weight: 700;
      background: url(~/assets/images/icon_check.png) no-repeat 0 0.3em;
      background-size: 1.3em auto;
      line-height: 1.7;
      :not(:last-of-type) {
        margin: 0 0 0.3em;
      }
    }
  }
}

/*---------------------------------------
.ranking
----------------------------------------*/
.ranking {
  margin-bottom: 8%;
  h2 {
    background: url(~/assets/images/icon_ranking.png) no-repeat 4.7% center,
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(244, 244, 244, 1) 100%
      );
    background-size: 5% auto, 100% 100%;
  }
}
.ranking-info {
  padding: 0 4% 1.5em;
  color: #666;
  font-size: px(10.9);
  line-height: 1.77;
}
.ranking-list {
  ul {
    padding: 4%;
    background-color: #5ba5cf;
    li {
      padding: 4%;
      display: flex;
      align-items: flex-start;
      background-color: #fff;
      &:not(:last-of-type) {
        border-bottom: 1px solid #5ba5cf;
      }
      & .rank {
        width: 21%;
        height: auto;
      }
      & .detail {
        padding: 0 0 0 4%;
        width: 79%;
        & .logo {
          margin: 0 0 3%;
          border: 1px solid #ccc;
          width: 34%;
          height: auto;
          box-sizing: border-box;
        }
        & .name {
          margin: 0 0 0.5em;
          font-size: px(12);
          font-weight: 700;
          span {
            display: block;
            font-size: px(21.1);
            font-weight: 700;
          }
        }
      }
    }
  }
}
.ranking-link {
  padding: 2% 0 7%;
  background-color: #5ba5cf;
  text-align: center;
  a {
    padding: 0 8% 0 0;
    background: url(~/assets/images/arrow_right_white.png) no-repeat right
      0.3em;
    background-size: 5% auto;
    span {
      padding: 0 0 2px;
      color: #fff;
      font-weight: 700;
      b {
        color: #fff800;
        font-weight: 700;
      }
    }
  }
}
.ranking-note {
  padding: 1% 4% 0;
  display: block;
  color: #666;
  font-size: px(10.9);
}

/*---------------------------------------
.main-compare
----------------------------------------*/
.main-compare {
  margin-bottom: 10%;
}

/*---------------------------------------
.beginner
----------------------------------------*/
.beginner {
  margin-bottom: 10.7%;
  h2 {
    background: url(~/assets/images/icon_beginner.png) no-repeat 5% center,
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(244, 244, 244, 1) 100%
      );
    background-size: 4% auto, 100% 100%;
  }
}
.beginner-flow {
  padding: 5% 4%;
  background: url(~/assets/images/bg_pattern_02.gif) no-repeat 0 0;
  background-size: 100% 100%;
  h3 {
    margin: 0 0 0.9em;
    padding: 0 0 0 0.5em;
    font-size: px(16.8);
    font-weight: 700;
    position: relative;
    line-height: 1.2;
    &::before {
      margin: 0 0.5em 0 0;
      content: '';
      width: 5px;
      height: 1em;
      background-color: $colorDarkBlue;
      position: absolute;
      top: 0.1em;
      left: 0;
    }
  }
  li {
    padding: 0.8em 1em;
    background-color: #fff;
    box-sizing: border-box;
    &:not(:last-of-type) {
      margin: 0 0 8%;
      position: relative;
      &::after {
        content: '';
        border: 10px solid transparent;
        border-top-color: #1b59b4;
        border-top-width: 10px;
        border-bottom-width: 0;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 200%);
      }
    }
    h4 {
      margin: 0 0 0.5em;
      color: $colorDarkBlue;
      font-size: px(16.8);
      font-weight: 700;
      img {
        margin: 0 0.5em 0 0;
        width: 23.2%;
        display: inline-block;
        vertical-align: middle;
      }
    }
    p {
      padding: 0 35% 0 0;
      min-height: 7em;
      font-size: px(12.9);
      line-height: 1.77;
    }
    &.beginner-flow-step01 {
      p {
        background: url(~/assets/images/icon_request.png) no-repeat 97% center;
        background-size: 25% auto;
      }
    }
    &.beginner-flow-step02 {
      p {
        background: url(~/assets/images/icon_estimation.png) no-repeat 97%
          center;
        background-size: 25% auto;
      }
    }
    &.beginner-flow-step03 {
      p {
        background: url(~/assets/images/icon_consideration.png) no-repeat 97%
          center;
        background-size: 25% auto;
      }
    }
  }
}

/*---------------------------------------
.voice
----------------------------------------*/
.voice {
  padding: 0 0 8%;
  h2 {
    background: url(~/assets/images/icon_voice.png) no-repeat 5% center,
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(244, 244, 244, 1) 100%
      );
    background-size: 6% auto, 100% 100%;
  }
}
.voice-list {
  padding: 0 4%;
  li {
    border: 1px solid $colorContentsBorder;
    border-radius: 2px;
    box-sizing: border-box;
    &:not(:last-of-type) {
      margin: 0 0 3%;
    }
  }
}
.voice-list-man {
  padding: 5.4% 4% 5.4% 22%;
  border-radius: 2px 2px 0 0;
  font-size: px(12.9);
  font-weight: 700;
  background: #ecf8ff url(~/assets/images/icon_man_40_01.png) no-repeat 4%
    center;
  background-size: 15% auto;
  box-sizing: border-box;
}
.voice-list-woman {
  padding: 5.4% 4% 5.4% 22%;
  border-radius: 2px 2px 0 0;
  font-size: px(12.9);
  font-weight: 700;
  background: #ecf8ff url(~/assets/images/icon_woman_40_01.png) no-repeat 4%
    center;
  background-size: 15% auto;
  box-sizing: border-box;
}
.voice-list-man,
.voice-list-woman {
  img {
    margin: 0 0 0.3em;
    width: 26%;
    display: block;
  }
  br {
    display: none;
  }
}
.voice-list-detail {
  padding: 1em;
  border-radius: 0 0 2px 2px;
  font-size: px(12.9);
  line-height: 1.77;
  span {
    margin: 0 0 0.5em;
    display: block;
    font-weight: 700;
    line-height: 1.53;
    box-sizing: border-box;
  }
}

/*---------------------------------------
.side-contents
----------------------------------------*/
.side-cont-list {
  margin: 0 4%;
  padding: 0 0.8em;
  li {
    &:not(:last-of-type) {
      border-bottom: 1px solid #dcdcdc;
    }
    a {
      padding: 0.8em 0;
      display: block;
      color: #1b59b4;
      line-height: 1.53;
      > div {
        margin: 0 0 0 20%;
        time {
          display: block;
          color: #666;
          font-size: px(12.9);
        }
        span {
          height: 3em;
          display: block;
          font-weight: 700;
          overflow: hidden;
        }
      }
    }
    img {
      float: left;
      width: 16%;
    }
  }
}
.side-request {
  background-color: #f0faff;
}
.side-request-catch {
  padding: 0.5em 0;
  color: #fff;
  font-size: px(19.4);
  background-color: $colorBasicBackground;
  text-align: center;
  b {
    color: #fff800;
  }
  br {
    display: none;
  }
}
.side-request-button {
  padding: 0 4% 7.2%;
  p {
    padding: 1em 0;
    font-size: px(12.9);
  }
}

/*---------------------------------------
.floating
----------------------------------------*/
.floating {
  width: 100%;
  background-color: rgba(30, 134, 198, 0.72);
  position: fixed;
  bottom: 0;
  left: 0;
}
.floating-button {
  padding: 2% 4% 3%;
}
.floating-button-catch {
  margin: 0 0 0.4em;
  color: #fff;
  font-size: px(16.8);
  font-weight: 700;
  text-align: center;
  span {
    color: #ff0;
    font-weight: 700;
    span {
      font-size: px(17.4);
    }
  }
}
.floating .button-step1 {
  margin: 0;
  width: 100%;
  > span {
    margin: 0 0.5em 0 0;
    padding: 0.2em 0.2em 0.3em;
  }
}
.floating-close {
  width: 6.4%;
  position: absolute;
  top: 7%;
  right: 3%;
  &.pc {
    display: none;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
@include media(pc) {
  /*---------------------------------------
.button
----------------------------------------*/
  .button-step1 {
    margin: 0 auto 80px;
    padding: 21px 0;
    width: 600px;
    box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    &:hover {
      background-color: #f78c35;
    }
    > span {
      margin: 6px 10px 0 0;
      padding: 2px 4px 5px;
      font-size: px(32);
    }
    > div {
      padding: 0 12% 0 0;
      font-size: px(36);
      background: url(~/assets/images/top/arrow_orange.png) no-repeat right
        center;
      background-size: 40px;
    }
  }
  .mainVisual-request {
    & .button-step1 {
      > div {
        background: url(~/assets/images/top/arrow_orange.png) no-repeat right
          center;
        background-size: 40px;
      }
    }
    & .request-other {
      margin: 0 auto;
      width: 600px;
      display: flex;
      justify-content: space-between;
    }
  }
  .button-lease {
    margin: 0;
    padding: 15px 10px;
    width: 312px;
    background: $colorSubButton url(~/assets/images/arrow_blue_sp.png)
      no-repeat 94% center;
    background-size: 20px 20px;
    vertical-align: middle;
    box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    &:hover {
      background: #efefef url(~/assets/images/arrow_blue_sp.png) no-repeat 94%
        center;
      background-size: 20px 20px;
    }
    > div {
      font-size: px(16);
    }
    span {
      font-size: px(11);
    }
  }
  .button-hojin {
    margin: 0 auto;
    padding: 13px 10px;
    width: 272px;
    display: flex;
    align-items: center;
    background: $colorSubButton url(~/assets/images/arrow_blue_sp.png)
      no-repeat right 24px center;
    background-size: 20px 20px;
    vertical-align: middle;
    box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    &:hover {
      background: #efefef url(~/assets/images/arrow_blue_sp.png) no-repeat
        right 24px center;
      background-size: 20px 20px;
    }
    > div {
      margin-left: 16px;
      font-size: px(16);
    }
  }
  .button-linkTopics {
    width: $pcWrapWidth;
    a {
      font-size: px(12.9);
      :not(:first-child) {
        margin: 0.4em 0 0;
      }
    }
  }
  .button-kyousai {
    margin: 0 0 20px;
    font-size: 100%;
    span {
      font-size: 71%;
    }
    br {
      display: none;
    }
  }

  /*---------------------------------------
.mainVisual
----------------------------------------*/
  .mainVisual {
    padding: 28px 0 64px;
    min-height: 420px;
    border-top: none;
    background: rgb(255, 255, 255); /* Old browsers */
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 0%,
      rgba(247, 246, 242, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 0%,
      rgba(247, 246, 242, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(247, 246, 242, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
  .mainVisual-header {
    margin-bottom: 23px;
    padding: 0;
    width: 761px;
    display: flex;
    align-items: center;
    h1 {
      margin-right: 18px;
      img {
        width: 500px;
      }
    }
    & .mainVisual-maxComs {
      width: 230px;
      height: 160px;
    }
  }
  .mainVisual-content {
    margin: 0 auto;
    display: flex;
    width: 954px;
    & .comList-wrap {
      margin: 7px 34px 0 0;
      width: 320px;
    }
    & .comList-title {
      background: $colorBlue;
      padding-bottom: 5px;
      font-weight: bold;
      color: $colorWhite;
      text-align: center;
      font-size: px(28);
      span {
        color: $colorYellow;
        font-weight: bold;
        strong {
          font-size: px(21.3);
        }
      }
    }
  }
  .mainVisual-request {
    margin: 0 0 0 auto;
    padding: 0;
    width: 600px;
  }
  .mainVisual-request-catch {
    margin: 0 auto 12px;
    width: 478px;
  }

  /*---------------------------------------
.anchor
----------------------------------------*/
  .anchor {
    display: none;
    > div {
      margin: 0 auto;
      padding: 17px 0 34px;
      width: $pcWrapWidth;
      h2 {
        margin: 0 0 0.5em;
        font-size: px(32);
        br {
          display: none;
        }
      }
      ul {
        display: flex;
        justify-content: space-between;
        li {
          margin: 0;
          width: 32.4%;
          &:not(:last-of-type) {
            margin: 0;
          }
          a {
            padding: 14px 0 10px;
            border-radius: 8px;
            font-size: px(18);
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 1) 0%,
              rgba(238, 238, 238, 1) 100%
            );
            background-size: 100% 100%;
            line-height: 1.3;
            &:hover {
              background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 1) 100%
              );
            }
            > div {
              padding: 50px 0 0 0;
              text-align: center;
            }
            span {
              font-size: px(21.6);
            }
          }
          &.anchor-comparison {
            a {
              > div {
                background: url(~/assets/images/icon_comparison.png) no-repeat
                  50% top;
                background-size: 44px auto;
              }
            }
          }
          &.anchor-ranking {
            a {
              > div {
                background: url(~/assets/images/icon_ranking.png) no-repeat 50%
                  top;
                background-size: 44px auto;
              }
            }
          }
          &.anchor-beginner {
            a {
              > div {
                background: url(~/assets/images/icon_beginner.png) no-repeat
                  50% top;
                background-size: 26px auto;
              }
            }
          }
          &.anchor-proposal {
            a {
              > div {
                background: url(~/assets/images/icon_proposal.png) no-repeat
                  50% top;
                background-size: 40px auto;
              }
            }
          }
        }
      }
    }
  }

  /*---------------------------------------
.request
----------------------------------------*/
  .request {
    margin: 6% 0 10%;
    border: 6px solid $colorBlue;
    border-radius: 4px;
  }
  .request-catch {
    margin: 0.3em auto;
    width: 15.6em;
    font-size: px(27.9);
    &::before,
    &::after {
      content: '';
      width: 1px;
      height: 80%;
      display: block;
      background-color: #fff;
      position: absolute;
      top: 15%;
    }
    &::before {
      left: -0.4em;
      transform: rotate(-45deg);
    }
    &::after {
      right: -0.4em;
      transform: rotate(45deg);
    }
    b {
      color: #fff800;
      font-weight: 700;
    }
  }
  .request-button {
    padding: 26px 0 31px;
    background-color: #fff;
  }
  .request .button-lease {
    margin: 0 auto 21px;
    width: 432px;
    background: $colorSubButton url(~/assets/images/arrow_blue.png) no-repeat
      93% center;
    background-size: 28px 28px;
    > div {
      font-size: px(24);
    }
    span {
      display: block;
      font-size: px(14);
    }
  }
  .request .button-hojin {
    width: 432px;
    background: $colorSubButton url(~/assets/images/arrow_blue.png) no-repeat
      94% center;
    background-size: 20px 20px;
    > div {
      font-size: px(16);
    }
  }

  /*---------------------------------------
.wrap-contents
----------------------------------------*/
  .wrap-contents {
    margin: 0 auto;
    padding: 60px 0 0;
    width: $pcWrapWidth;
    display: flex;
    justify-content: space-between;
  }
  .pageTop {
    @include pageTop(pc);
  }
  /*---------------------------------------
.main-contents
----------------------------------------*/
  .main-contents {
    padding: 0;
    width: $pcMainContentsWidth;
    section {
      h2 {
        margin: 0 0 12px;
        padding: 10px 1em 10px 55px;
        border-top: 3px solid $colorContentsBorder;
        font-size: px(21.9);
      }
      p[class$='-lead'] {
        padding: 0 0 1.5em;
        font-size: px(15);
      }
    }
  }

  /*---------------------------------------
.comparison
----------------------------------------*/
  .comparison {
    padding: 0 0 80px;
    h2 {
      background: url(~/assets/images/icon_comparison.png) no-repeat 15px
          center,
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(244, 244, 244, 1) 100%
        );
      background-size: 30px auto, 100% 100%;
    }
  }

  /*---------------------------------------
.cate
----------------------------------------*/
  .cate {
    padding: 0 0 40px;
    h2 {
      margin: 0 0 12px;
      padding: 10px 1em 10px 55px;
      border-top: 3px solid $colorContentsBorder;
      font-size: px(21.9);
      background:
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(244, 244, 244, 1) 100%
        );
      background-size: 27px auto, 100% 100%;
      &.new {
        background: url(~/assets/images/icon_fire.png) no-repeat 18px center;
        background-size: 27px auto, 100% 100%;
      }
      &.earthquake {
        background: url(~/assets/images/icon_earthquake.png) no-repeat 18px center;
        background-size: 27px auto, 100% 100%;
      }
    }
    p {
      padding: 0 0 1.5em;
      font-size: px(15);
    }
    .cate-recommend {
      margin: 0 0 20px;
      padding: 17px 19px;
      .cate-recommend-title {
        margin: 0 0 12px;
        font-size: px(18.9);
        img {
          width: 80px;
        }
      }
      li {
        padding: 0 0 0 37px;
        font-size: px(15);
      }
    }
  }


  /*---------------------------------------
.ranking
----------------------------------------*/
  .ranking {
    margin-bottom: 0;
    &.ranking {
      h2 {
        background: url(~/assets/images/icon_ranking.png) no-repeat 15px center,
          linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(244, 244, 244, 1) 100%
          );
        background-size: 29px auto, 100% 100%;
      }
    }
    .ranking-info {
      padding: 0 0 1.5em;
      font-size: px(10.9);
    }
    .ranking-list {
      ul {
        padding: 14px;
      }
      li {
        padding: 19px 24px;
        align-items: center;

        .rank {
          width: 65px;
        }
        .detail {
          padding: 0 0 0 18px;
          width: 507px;
          display: flex;
          align-items: center;
          .logo {
            margin: 0 18px 0 0;
            width: 84px;
          }
          .name {
            font-size: px(15);
            span {
              font-size: px(18);
            }
          }
        }
      }
    }

    .ranking-link {
      padding: 10px 0 30px;
      a {
        padding: 0 34px 0 0;
        background: url(~/assets/images/arrow_right_white.png) no-repeat right
          0.3em;
        background-size: 11px 15px;
        span {
          padding: 0 0 2px;
          font-size: px(18);
        }
      }
    }

    .ranking-note {
      padding: 10px 0 0;
      font-size: px(10.9);
    }
  }

  /*---------------------------------------
.beginner
----------------------------------------*/
  .beginner {
    h2 {
      background: url(~/assets/images/icon_beginner.png) no-repeat 21px center,
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(244, 244, 244, 1) 100%
        );
      background-size: 19px auto, 100% 100%;
    }
    .beginner-flow {
      padding: 0;
      background: none;
      h3 {
        margin: 0 0 1.1em;
        padding: 0 0 0.3em 0.6em;
        border-bottom: 1px solid #dcdcdc;
        font-size: px(21.9);
      }
      ol {
        display: flex;
        justify-content: space-between;
      }
      li {
        padding: 21px 15px;
        width: 196px;
        min-height: 370px;
        background: url(~/assets/images/bg_pattern_02.gif) no-repeat 0 0;
        background-size: 100% 100%;
        &:not(:last-of-type) {
          margin: 0 26px 0 0;
          &::after {
            content: '';
            border: 10px solid transparent;
            border-left-color: #1b59b4;
            border-left-width: 10px;
            border-right-width: 0;
            position: absolute;
            top: 50%;
            right: 0;
            left: auto;
            bottom: auto;
            transform: translate(19px, -50%);
          }
        }
        h4 {
          margin: 0 0 1.3em;
          color: $colorDarkBlue;
          font-size: px(18.9);
          font-weight: 700;
          text-align: center;
          img {
            margin: 0 auto 5px;
            width: 92px;
            height: 24px;
            display: block;
          }
        }
        p {
          padding: 89px 0 0 0;
          min-height: 7em;
          font-size: px(15);
        }
        &.beginner-flow-step01 {
          p {
            background: url(~/assets/images/icon_request.png) no-repeat center
              top;
            background-size: 74px auto;
          }
        }
        &.beginner-flow-step02 {
          p {
            background: url(~/assets/images/icon_estimation.png) no-repeat
              center top;
            background-size: 76px auto;
          }
        }
        &.beginner-flow-step03 {
          p {
            background: url(~/assets/images/icon_consideration.png) no-repeat
              center top;
            background-size: 94px auto;
          }
        }
      }
    }
    margin-bottom: 0 0 80px;
  }

  /*---------------------------------------
.voice
----------------------------------------*/
  .voice {
    padding: 0 0 40px;
    h2 {
      background: url(~/assets/images/icon_voice.png) no-repeat 20px center,
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(244, 244, 244, 1) 100%
        );
      background-size: 28px auto, 100% 100%;
    }
    .voice-list {
      padding: 0;

      li {
        border-radius: 4px;
        width: 100%;
        min-height: 290px;
        display: flex;

        div:first-child {
          width: 175px;
        }

        div:last-child {
          width: 478px;
        }

        &:not(:last-of-type) {
          margin: 0 0 15px;
        }
      }
    }
    .voice-list-man,
    .voice-list-woman {
      padding: 150px 20px 0 20px;
      border-radius: 4px 0 0 4px;
      font-size: px(15);
      text-align: center;
      img {
        margin: 0 auto 16px;
        width: 100px;
        height: 24px;
        display: block;
      }
      br {
        display: block;
      }
    }
    .voice-list-man {
      background: #ecf8ff url(~/assets/images/icon_man_40_01.png) no-repeat
        center 35px;
      background-size: 100px 100px;
    }
    .voice-list-woman {
      background: #ecf8ff url(~/assets/images/icon_woman_40_01.png) no-repeat
        center 35px;
      background-size: 100px 100px;
    }
    .voice-list-detail {
      padding: 22px 30px;
      border-radius: 0 4px 4px 0;
      font-size: px(15);

      span {
        font-size: px(18.9);
      }
    }
  }

  /*---------------------------------------
.side-cont-new
----------------------------------------*/
  .side-cont-list {
    margin: 0;
    padding: 0 21px;
    border: 1px solid #dcdcdc;
    li {
      &:not(:last-of-type) {
        border-bottom: 1px solid #dcdcdc;
      }
      a {
        padding: 21px 0;
        line-height: 1.53;
        &:hover {
          opacity: 0.7;
        }
        > div {
          margin: 0 0 0 100px;
          time {
            font-size: px(15);
          }
          span {
            height: 4.6em;
          }
        }
      }
      img {
        width: 90px;
        height: 90px;
      }
    }
  }

  .side-request-catch {
    padding: 10px 0;
    font-size: px(21.9);
    font-weight: 700;
    b {
      font-weight: 700;
    }
    br {
      display: block;
    }
  }

  .side-request-button {
    padding: 0 21px 18px;
    p {
      padding: 20px 0;
      font-size: px(15);
    }
    .button-step1 {
      margin: 0 auto 3%;
      padding: 10px 0;
      width: 100%;
      > span {
        margin: 0 auto;
        padding: 0.2em 0.2em 0.2em;
        width: 2.8em;
        display: block;
        font-size: px(18.9);
      }
      > div {
        padding: 0;
        font-size: px(21.9);
        background: none;
      }
    }
  }

  /*---------------------------------------
  .floating
  ----------------------------------------*/
  .floating {
    > div {
      margin: 0 auto;
      width: $pcWrapWidth;
      position: relative;
    }
    .button-step1 {
      margin: 0;
      width: 500px;
      > span {
        font-size: px(19.9);
      }
      > div {
        font-size: px(30);
        background-size: 32px;
      }
    }
    .floating-button {
      padding: 26px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .floating-button-catch {
      margin: 0 0 0.4em;
      font-size: px(30);
      text-align: center;
      span {
        span {
          font-size: px(19.9);
        }
      }
    }

    .floating-close {
      width: 100px;
      height: 35px;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(0, -100%);
      &.pc {
        display: block;
      }
      &.sp {
        display: none;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>

